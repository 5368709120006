<template>
  <div style="height:250px;">
    <canvas ref="horizontalChart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'HorizontalChart',
  //components: {
  //  Chart
  //},
  props:{
    labels:{
      type: Array,
      default: null
    },
    dataset:{
      type: Array,
      default: null
    },
    
  },
  mounted() {
    this.createhorizontalChart()
  },
  methods: {
    createhorizontalChart() {
      const horizontalChartCanvas = this.$refs.horizontalChart //.getContext('2d');
      const data = {
        labels: this.labels,
        datasets: [
          {
            data: this.dataset,
            backgroundColor: ['#FFB100', '#00aeff',  '#8dc63f', '#782eff'],
            label:null,
            datalabels: {
              align: 'end',
              anchor: 'start'
            }
          },
          {
            data: this.dataset.map(d=> 100-d),
            backgroundColor: ['#F3F3F3', '#F3F3F3', '#F3F3F3', '#F3F3F3'],
            label:null,
            datalabels: {
              labels: {
                title: null
              }
            }
          },
        ],
      };
      // Moyenne par categorie: A: 38,84057971	I: 49,63768116	Rf:28,01932367	Re:32,97101449
      const customLinesValue = [39,	50,	28,	33]
      const options = {
        legend: {
          display: false // Masquer la légende
        },
        scales: {
          yAxes: [{
            stacked: true,
            // display:false
          }],
            xAxes: [{
              stacked: true,
              display:false,
              ticks: {
                  beginAtZero: true, // Début de l'axe des abscisses à zéro
                  min:0,
                  max:100
              }
            }]
        },
        tooltips: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'black',
            display: function(context) {
              return context.dataset.data[context.dataIndex] > 15;
            },
            font: {
              weight: 'bold'
            },
            formatter: Math.round
          }
        },
      }
            
      var originalLineDraw = Chart.controllers.bar.prototype.draw;
      Chart.helpers.extend(Chart.controllers.bar.prototype, {
        draw: function () {
          // use the base draw function
          originalLineDraw.apply(this, arguments);

          var lineValues = customLinesValue;
          var ctx = this.chart.chart.ctx;
          //console.log('chart',this.chart)
        
          var xAxis = this.chart.scales['x-axis-0'];
          //var yAxis = chart.scales['y-axis-0'];
          var chartArea = this.chart.chartArea;
          lineValues.forEach((line,i) => {            
            var xPosition = xAxis.getPixelForValue(line);
            var lineTop = chartArea.top + chartArea.bottom/4 *i
            var lineBottom = chartArea.bottom/4 *(i+1)

            // Dessine le trait de couleur sur la barre spécifiée
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(xPosition, lineTop);
            ctx.lineTo(xPosition, lineBottom);
            ctx.lineWidth = 2;
            ctx.strokeStyle = 'red';
            ctx.stroke();
            ctx.restore();                                   // save it as a property so it can be accessed from the draw method
          })
        }
      });

      new Chart(horizontalChartCanvas, {
        type: 'horizontalBar',
        data: data,
        options: options,
        plugins: [ChartDataLabels],
      });

      
    },
  },
};
</script>

<style>
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px; /* Spécifiez la largeur souhaitée */
  height: 250px; /* Spécifiez la hauteur souhaitée */
}
</style>