<template>
    <div>
        <h1>Loading data from JotForms ...</h1>
        <v-progress-circular
        style="
          margin-left: auto;
          display: block;
          margin-right: auto;"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </template>
  
  <script lang="ts">
  
  
  export default {
    name: 'LoadingPage',
  
    components: {
    },
  
    data: () => ({
      
    }),
    mounted() {
    
    },
    methods: {
      
    }
  };
  </script>
  
  <style scoped>
  
  
  </style>