<template>
 
    <div>
    <!-- Grande zone d'image sur toute la largeur -->
    <v-row>
      <v-col cols="12">
        <!-- Vous pouvez remplacer "image.jpg" par l'URL de votre propre image -->
        <img src="3506328.jpg" alt="Image d'arrière-plan" style="width: 100%; height: 80%;" />
        <!-- image source: https://img.freepik.com/vecteurs-libre/papier-peint-geometrique-design-graphique_52683-34399.jpg" -->
        <!-- Zone de texte et bouton d'action superposés -->
        <div class="text-overlay">
          <div class="text-section">
            <h1>Le test de mesure d'impact de votre entreprise</h1>
            <h3 class="amber--text text--darken-1">"L'innovation doit générer plus d'avantages que d'effets négatifs"</h3>
            <p>Dedié aux entreprises soucieuses de mesurer l'impact de leur innovation en quelques minutes. </p>
            <p><strong>Suivez-le mouvement, faites le test !</strong></p>            
            <!-- Bouton d'action -->
            <a href="https://eu.jotform.com/241222349948360" target="_blank">
            <v-btn color="#FFB100" >Faire le test</v-btn>
            </a>
            <!--v-btn text-color="secondary" @click="readMore">Découvrir le projet</v-btn-->
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name:'HomePage',
  mounted() {
    console.log('route',this.$route, this.$route.params.id)
    //if(this.$route.name ==='results' && this.$route.params.id) {
    //  this.$router.push(`/results/${this.$route.params.id}`)
    //}
  },
  methods: {
  },
};
</script>

<style scoped>
.text-overlay {
  position: relative;
}

.text-section {
  position: absolute;
  top: 50%;
  left: 10%; /* Ajustez la valeur pour le décalage horizontal */
  transform: translateY(-150%);
  background-color: rgba(255, 255, 255, 0.9); /* Arrière-plan semi-transparent pour le texte */
  padding: 20px;
  text-align: left;
  max-width: 450px; /* Ajustez la largeur de la zone de texte selon vos besoins */
  
}

.text-section h1 {
  color: black; /* Texte en noir */
  font-family: "Roboto", sans-serif; /* Remplacez "Votre Police Arrondie" par le nom de votre police */
  font-size: 40px; /* Ajustez la taille de la police selon vos besoins */
  margin-bottom:20px;
}

.text-section h3 {
  
  font-family: "Roboto", sans-serif; /* Remplacez "Votre Police Arrondie" par le nom de votre police */
  font-size: 24px; /* Ajustez la taille de la police selon vos besoins */
  margin-bottom:16px;
}

.text-section p {
  margin: 30;
}

.text-section button {
  margin-top: 10px;
}

</style>
