<template>
  
      <!-- WHILE NO DATA -->
      <loading style="margin: auto;" v-if="!formResponsesParsed"/>
    <!-- WHEN DATA LOADED -->
    <!-- <div style="margin: auto;" v-else>
      <div v-for="answer in formResponsesParsed" :key="answer[0]">
        {{  answer[0] }} : {{ answer[1] }}
      </div>
    </div> -->
    <div class="group" v-else>
      <v-card class="card-group-description mb-4">
        <v-card-title class="group-title justify-center">Votre profil est : </v-card-title>
        <v-card-title class="group-title justify-center">{{ groupsTitle[dominantGroup] }}  à  {{ percentageGroup[dominantGroup] }} %</v-card-title>
        <!--v-card-title class="group-title justify-center">Vos scores par groupe {{userPoints}}</v-card-title>
        <v-card-title class="group-title justify-center">Vos scores par groupe en % {{percentageGroup}}</v-card-title-->
        <v-card-text class="text-center">
          {{groupsResume[dominantGroup] }}
        </v-card-text>
      
        <v-card-text>
          <donut-chart
          :labels="Object.keys(groupsTitle)"
          :dataset="Object.values(percentageGroup).sort((a,b) => b-a)"
          :centerValue="percentageGroup[dominantGroup]"
          ></donut-chart>
        </v-card-text>
      </v-card>
     
      <v-card class="card-group-description mb-4" >
        <v-card-title class="group-title justify-center">Vos scores par catégorie</v-card-title>
        <v-card-text class="text-center">
        La ligne rouge représente la moyenne des répondants
        </v-card-text>
         <v-card-text class="text-center">
         <horizontal-chart 
          :labels="labels"
          :dataset="Object.values(percentageCategory).sort((a,b) => b-a)"
          ></horizontal-chart>
        </v-card-text>
      </v-card>
      <v-card class="card-group-description mb-4" >
        <v-card-title class="group-title justify-center">Vos atouts</v-card-title>
        <v-card-text class="text-center">
        Accentuez vos points forts pour vous distinguer!
        </v-card-text>
          <v-card-text>
          <v-list>
            <v-list-item v-for="up in ups[dominantGroup]" :key="up.id">
              <v-list-item-icon>
                <v-icon color='green'>mdi-star</v-icon>
              </v-list-item-icon>
               <v-list-item-content>
              {{up}}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          </v-card-text>
        </v-card>
        <v-card class="card-group-description mb-4" >
        <v-card-title class="group-title justify-center">Les points d'amélioration</v-card-title>
          <v-card-text class="text-center">
        Ne les laissez pas de côté c'est important!
        </v-card-text>
        <v-card-text >
          <v-list>
            <v-list-item v-for="down in downs[dominantGroup]" :key="down.id">
              <v-list-item-icon>
                <v-icon color='blue'>mdi-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
              {{down}}
              </v-list-item-content>
            </v-list-item>
           </v-list>         
          </v-card-text>
        </v-card>
     
        <v-card class="card-group-description mb-4" >
        <v-card-title class="group-title justify-center">Description générale</v-card-title>
          <v-card-text class="text-center" style="color: #000000DE; text-align: left !important;">
              {{ groupsDescription[dominantGroup] }}
          </v-card-text>
        </v-card>
     
   </div>

 
</template>

<script>

import Loading from './components/LoadingPage.vue'
import DonutChart from './components/DonutChart.vue'
import HorizontalChart from './components/HorizontalChart.vue'

export default {
  name: 'ResultsPage',

  components: {
    Loading, 
    DonutChart,
    HorizontalChart
  },

  data: () => ({
    profileCriteria: {
      1: {
        11: {
          "Absolument nécessaire": "P1",
          "Moyennement nécessaire": "P2",
          "Pas nécessaire": "n/a"
        },
        12: {
          "Absolument nécessaire": "n/a",
          "Moyennement nécessaire": "n/a",
          "Pas nécessaire": "n/a"
        },
        13: {
          "Absolument nécessaire": "P1",
          "Moyennement nécessaire": "P3",
          "Pas nécessaire": "n/a"
        },
        14: {
          "Absolument nécessaire": "n/a",
          "Moyennement nécessaire": "n/a",
          "Pas nécessaire": "P2"
        },
        15: {
          "Absolument nécessaire": "P2",
          "Moyennement nécessaire": "P1",
          "Pas nécessaire": "n/a"
        },
      },
      2: {
        "Nécessaire": "P2",
        "Pas nécessaire": "n/a"
      },
      3: {
        31: {
          "Absolument nécessaire": "n/a",
          "Moyennement nécessaire": "n/a",
          "Pas nécessaire": "n/a"
        },
        32: {
          "Absolument nécessaire": "P2",
          "Moyennement nécessaire": "n/a",
          "Pas nécessaire": "P3"
        },
        33: {
          "Absolument nécessaire": "P2",
          "Moyennement nécessaire": "P1",
          "Pas nécessaire": "n/a"
        },
        34: {
          "Absolument nécessaire": "P1",
          "Moyennement nécessaire": "n/a",
          "Pas nécessaire": "P3"
        }
      },
      4: {
        41: {
          "Absolument nécessaire": "n/a",
          "Moyennement nécessaire": "P1",
          "Pas nécessaire": "n/a"
        },
        42: {
          "Absolument nécessaire": "P1|P2", // truth: P1|P2
          "Moyennement nécessaire": "P1|P2", // '' '' P3
          "Pas nécessaire": "n/a"
        },
        43: {
          "Absolument nécessaire": "n/a",
          "Moyennement nécessaire": "n/a",
          "Pas nécessaire": "P3"
        }
      },
      5: {
        51: {
          "Absolument nécessaire": "n/a",
          "Moyennement nécessaire": "P1",
          "Pas nécessaire": "P3"
        },
        52: {
          "Absolument nécessaire": "P1",
          "Moyennement nécessaire": "P3",
          "Pas nécessaire": "n/a"
        },
        53: {
          "Absolument nécessaire": "P2",
          "Moyennement nécessaire": "n/a",
          "Pas nécessaire": "P1"
        },
        54: {
          "Absolument nécessaire": "P2",
          "Moyennement nécessaire": "n/a",
          "Pas nécessaire": "n/a"
        }
      }
    },
    valuesOfResponses: {
      1: {
        11: {
          "Absolument nécessaire": "A_EUT+",
          "Moyennement nécessaire": "A_EUT+-",
          "Pas nécessaire": "A_EUT-"
        },
        12: {
          "Absolument nécessaire": "A_EOP+",
          "Moyennement nécessaire": "A_EOP+-",
          "Pas nécessaire": "A_EOP-"
        },
        13: {
          "Absolument nécessaire": "A_DB+",
          "Moyennement nécessaire": "A_DB+-",
          "Pas nécessaire": "A_DB-"
        },
        14: {
          "Absolument nécessaire": "A_EIS+",
          "Moyennement nécessaire": "A_EIS+-",
          "Pas nécessaire": "A_EIS-"
        },
        15: {
          "Absolument nécessaire": "A_EMP+",
          "Moyennement nécessaire": "A_EMP+-",
          "Pas nécessaire": "A_EMP-"
        },
      },
      2: {
        "Nécessaire": "A_ERE+",
        "Pas nécessaire": "A_ERE-"
      },
      3: {
        31: {
          "Absolument nécessaire": "I_PPP+",
          "Moyennement nécessaire": "I_PPP+-",
          "Pas nécessaire": "I_PPP-"
        },
        32: {
          "Absolument nécessaire": "I_MPP+",
          "Moyennement nécessaire": "I_MPP+-",
          "Pas nécessaire": "I_MPP-"
        },
        33: {
          "Absolument nécessaire": "I_TPP+",
          "Moyennement nécessaire": "I_TPP+-",
          "Pas nécessaire": "I_TPP-"
        },
        34: {
          "Absolument nécessaire": "I_RRC+",
          "Moyennement nécessaire": "I_RRC+-",
          "Pas nécessaire": "I_RRC-"
        }
      },
      4: {
        41: {
          "Absolument nécessaire": "Rf_UPC+",
          "Moyennement nécessaire": "Rf_UPC+-",
          "Pas nécessaire": "Rf_UPC-"
        },
        42: {
          "Absolument nécessaire": "Rf_IAS+",
          "Moyennement nécessaire": "Rf_IAS+-",
          "Pas nécessaire": "Rf_IAS-"
        },
        43: {
          "Absolument nécessaire": "Rf_CCD+",
          "Moyennement nécessaire": "Rf_CCD+-",
          "Pas nécessaire": "Rf_CCD-"
        }
      },
      5: {
        51: {
          "Absolument nécessaire": "Re_EOD+",
          "Moyennement nécessaire": "Re_EOD+-",
          "Pas nécessaire": "Re_EOD-"
        },
        52: {
          "Absolument nécessaire": "Re_REP+",
          "Moyennement nécessaire": "Re_REP+-",
          "Pas nécessaire": "Re_REP-"
        },
        53: {
          "Absolument nécessaire": "Re_REP+",
          "Moyennement nécessaire": "Re_PDPP+-",
          "Pas nécessaire": "Re_PDPP-"
        },
        54: {
          "Absolument nécessaire": "Re_TDPP+",
          "Moyennement nécessaire": "Re_TDPP+-",
          "Pas nécessaire": "Re_TDPP-"
        }
      },
    },
    groupsTitle: {
      'P1': `Intégrateur moyen d'Innovation Responsable`,
      'P2': `Intégrateur fort d'Innovation Responsable`,
      'P3': `Non intégrateur d'Innovation Responsable`
    },
    groupsDescription: {
      'P1': `Le profil "intégrateur moyen d'Innovation Responsable" représente 32% des répondants. Ce groupe est associé principalement aux entreprises qui développent des technologies de l'agriculture de précision et des logiciels de gestion. Il ne présente pas de caractéristiques remarquables selon la taille d'entreprise. Nous pouvons retrouver cependant plusieurs profils qui correspondent à des microentreprises. Ce profil se caractérise par la mise en œuvre d'enquêtes ex-ante sur l'utilisation envisageable des technologies numériques par les agriculteurs ainsi que le diagnostic des besoins d'exploitants agricoles. Le niveau d'implémentation de ces activités est généralement élevé. Nous retrouvons également des niveaux moyens d'évaluation participative à mi-parcours des projets d'innovation. Cette évaluation ne s'accompagne pas systématiquement de la prise en compte des retours d'expérience des participants vis-à-vis de la démonstration de prototypes. En outre, l'inclusion de parties prenantes, lorsqu'elle est effective, se fait généralement aux phases tardives du processus d'innovation en incluant la recherche de retour critique de la part des participants. Dans ce profil, la réflexivité est déterminée par la consultation et l'importance des acteurs sociaux en plus des autres participants. Enfin, nous notons des niveaux significatifs d'échanges ouverts de données et d'informations sur les activités d'innovation. Par contre, la réactivité suite aux débats et discussions avec les parties prenantes (toutes confondues) demeure absente, en particulier aux phases précoces du processus.`,
      'P2': `Le profil "intégrateur d'Innovation Responsable" représente 50% des répondants. Il semble davantage associé aux microentreprises et aux petites entreprises. Le secteur d'activité est généralement celui des technologies de l'agriculture de précision. Notons que l'exercice de prospective caractérisant ce profil se traduit essentiellement par des enquêtes préalables, à fréquence moyenne, sur l'utilisation envisageable de la technologie. L'évaluation participative des technologies, lorsqu'elle est mise en œuvre, se fait d'ordinaire à mi-parcours des projets d'innovation, et inclut la prise en compte du retour d'expérience des participants. Cependant, elle ne s'accompagne pas de l'évaluation d'impact social. L'inclusion des parties prenantes, quant à elle, se fait de manière plus significative aux phases intermédiaires et tardives du processus d'innovation. La réflexivité est essentiellement guidée par l'implication des acteurs sociaux, et l'importance relativement élevée qu'ils revêtent. Enfin, contrairement au profil "intégrateur moyen", nous notons une double réorientation ou réactivité (aux phases précoces et tardives) de la mise au point de l'innovation à la suite d'échanges avec les parties prenantes externes.`,
      'P3': `Le profil "non intégrateur d'Innovation Responsable" représente 18% des répondants. Il semble davantage associé aux entreprises de tailles moyennes dont le domaine d'activité est généralement celui des technologies de l'agriculture de précision et de la robotique. Ce profil se caractérise par une absence d'évaluation participative des technologies numériques développées. Nous notons également une absence d'indicateur permettant d'envisager le futur de la technologie à moyen et long terme, à l'exception d'un niveau moyen de mise en œuvre du diagnostic des besoins des exploitants agricoles. L'inclusion des parties prenantes n'est pas spécifiée, mais elle est manifestement absente au stade intermédiaire du processus d'innovation. La réflexivité demeure ici limitée à l'importance relativement moyenne que présentent les acteurs sociaux lorsque ceux-ci sont impliquées. Enfin, la réactivité se traduit de manière quasi exclusive par l'adaptation des trajectoires d'innovation suite à l'évaluation pré-lancement des outils technologiques.`
    },
    groupsResume: {
      'P1':`Ce profil se caractérise par la mise en œuvre d'enquêtes ex-ante sur l'utilisation envisageable des technologies numériques par les agriculteurs.`,
      'P2':`L'exercice de prospective caractérisant ce profil se traduit essentiellement par des enquêtes préalables, à fréquence moyenne, sur l'utilisation envisageable de la technologie.`,
      'P3':`Ce profil se caractérise par peu ou une absence totale d'indicateur permettant d'envisager le futur des technologie à moyen et long terme.`
    },
    downs: {
      'P1': [
        "Aborder les aspects sociaux des technologies, dont le développement de nouvelles compétences ou encore l'amélioration des conditions de travail des utilisateurs par la recherche de confort et de gain de temps",
        "Améliorer la compréhension mutuelle entre l'équipe projet et l'ensemble des partenaires externes du projet",
        "Ouvrir le débat sur les impacts des technologies avec les acteurs",
        "Créer les conditions favorables pour anticiper les impacts"
      ],
      'P2': [
        "Mettre la performance environnementale et la sobriété numérique des technologiques au coeur des priorités", 
        "Faire une analyse des conséquences du changement technologique sur les utilisateurs et l'environnement",
        "Créer un code de conduite dans les pratiques d'innovation au sein du projet afin de guider la réflexivité et une posture plus critique"
      ],
      'P3': [
        "Travailler sur l'évaluation participative de la technologie avant son lancement sur le marché",
        "Impliquer un large éventail de parties prenantes, de dialogue constructif, et la recherche de retour critique de la part des différentes parties",
        "Créer en interne des codes de conduite afin d'adopter colectivement une posture réflexive"
      ]
    },
    ups: {
      'P1': [
        "Insister sur la question de l'interopérabilité des technologies développées",
        "Créer des outils de mesure de votre performance environnementale et de votre impact numérique",
      ],
      'P2': [
        "Apporter une attention particulière à l'appropriation des technologies numériques par les utilisateurs", 
        "Inciter à la prise de décision des utilisateurs, la réduction de la pénibilité de leurs tâches professionnelles", 
        "Favoriser l'autonomie décisionnelle"
      ],
      'P3': [
        "Stratégie d'innovation peu centrée sur des facteurs de durabilité des technologies"
      ]
    },
    formId: '',
    formResponses: '',
    formResponsesParsed: '',
    userPoints: {
      'P1': 0,
      'P2': 0,
      'P3': 0
    },
    categoryPoints: {
      'A': 0,
      'I': 0,
      'Rf': 0,
      'Re': 0
    },
    categoryLabels: {
      'A': 'Anticipation',
      'I': 'Inclusion',
      'Rf': 'Réflexivité',
      'Re':'Réactivité'
    },
    maxPointByCategory: {
      'A': 15,
      'I': 12,
      'Rf': 9,
      'Re': 12
    }
  }),
  async mounted() {
    this.formId = this.$route.params.id
    //console.log("calling webhook",this.formId)
    let response = await this.getFormData();
    //console.log("data",response)
    this.formResponses = response.data;
    this.parseData();
  },
  methods: {
    async getFormData() {
      let resp;
      try {
        resp = this.$http.post('https://hook.eu1.make.com/ghpkt3665gexcp5roujpvadr0fylhsrr', {data: this.formId})
        .then((r) => resp = r)
      }
      catch(e) {
        console.error(e);
      }
      return resp;
    },
    parseData() {
      //console.log("starting parsing")
      this.formResponsesParsed = Object.values(this.formResponses).map((q) => {
        let qNumber = q.name.charAt(0);
        if (qNumber!=="2") { // question 2 different parsing because no sub-question
          Object.values(q.answer).map((a, index) => {
            let subQNumber = index+1 + qNumber*10;
            let codes = this.valuesOfResponses[qNumber][subQNumber];
            let thisQuestionCriterias = this.profileCriteria[qNumber][subQNumber];
            if (thisQuestionCriterias[a]!=='n/a' && !thisQuestionCriterias[a].includes('|')) // IF ANSWER HAS CORRESPONDING GROUP && ONLY ONE GROUP
              this.userPoints[thisQuestionCriterias[a]]+=1; // ADDING ONE POINT TO GROUP
            else if(thisQuestionCriterias[a]!=='n/a' && thisQuestionCriterias[a].includes('|')) { // If multiple groups possible with this answer
              let groups = thisQuestionCriterias[a].split('|')
              groups.forEach(g => { // ADDING POINT FOR EACH GROUP
                this.userPoints[g]+=1;
              })
            }
            // ADDING POINT TO CATEGORY
            if (a.toLowerCase().includes('absolument')) {this.categoryPoints[codes[a].split('_')[0]]+=3; }
            else if (a.toLowerCase().includes('moyennement')) {this.categoryPoints[codes[a].split('_')[0]]+=1; }
            // else if (r.toLowerCase().includes('pas')) {this.categoryPoints[codes[r].split('_')[0]]+=0; }
            
            return [subQNumber, codes[a]] // RETURN ARRAY TYPE ['31', 'I_PPP+']
          })
        } else {
          if (this.profileCriteria[2][q.answer]!=='n/a') {
            this.userPoints[this.profileCriteria[2][q.answer]]+=1;
          }
          // ADDING POINT TO CATEGORY
          if (!q.answer.toLowerCase().includes('pas')) {this.categoryPoints['A']+=1; }
          return [qNumber, this.valuesOfResponses[2][q.answer]]
        }



        // let qNumber = q.name.charAt(0); // FIRST NUMBER OF THE QUESTION (exemple 1 for question 1-4)
        // let qLabel = q.name // FULL QUESTION NUMBER (type: 1-2, 3-3);
        // if (qNumber!=="2") { // question 2 different parsing because no sub-question
        //   let codes = this.valuesOfResponses[qNumber][qLabel.replace('-', '')];
        //   let r = Object.values(q.answer)[0] // LABEL OF RESPONSE ('Nécéssaire', 'Pas nécéssaire')
        //   let thisQuestionCriterias = this.profileCriteria[qNumber][qLabel.replace('-','')]; // Obj containing groups of possible responses at this question
        //   if (thisQuestionCriterias[r]!=='n/a' && !thisQuestionCriterias[r].includes('|')) // IF ANSWER HAS CORRESPONDING GROUP && ONLY ONE GROUP
        //     this.userPoints[thisQuestionCriterias[r]]+=1; // ADDING ONE POINT TO GROUP
        //   else if(thisQuestionCriterias[r]!=='n/a' && thisQuestionCriterias[r].includes('|')) { // If multiple groups possible with this answer
        //     let groups = thisQuestionCriterias[r].split('|')
        //     groups.forEach(g => { // ADDING POINT FOR EACH GROUP
        //       this.userPoints[g]+=1;
        //     })
        //   }
        //   // ADDING POINT TO CATEGORY
        //   if (r.toLowerCase().includes('absolument')) {this.categoryPoints[codes[r].split('_')[0]]+=3; }
        //   else if (r.toLowerCase().includes('moyennement')) {this.categoryPoints[codes[r].split('_')[0]]+=1; }
        //   // else if (r.toLowerCase().includes('pas')) {this.categoryPoints[codes[r].split('_')[0]]+=0; }
          
        //   return [qLabel, codes[r]] // RETURN ARRAY TYPE ['3-1', 'I_PPP+']
        // } else {
        //   if (this.profileCriteria[2][q.answer]!=='n/a') {
        //     this.userPoints[this.profileCriteria[2][q.answer]]+=1;
        //   }
        //   // ADDING POINT TO CATEGORY
        //   if (!q.answer.toLowerCase().includes('pas')) {this.categoryPoints['A']+=1; }
        //   return [qLabel, this.valuesOfResponses[2][q.answer]]
        // }
        
      })
      //console.log("parsing done")
    },
  },
  computed: {
    dominantGroup() {
      // eslint-disable-next-line
      return Object.entries(this.userPoints).find(([k, v]) => v === Math.max(...Object.values(this.userPoints)))[0]
    },
    percentageGroup() {
      const p={}
      const total = Object.values(this.userPoints).reduce((acc, valeur) => acc + valeur, 0);
      Object.values(this.userPoints).forEach((v,i) => 
        p[`P${i+1}`]= Math.round(v/total * 100)
      )
      return p
    },
    percentageCategory() {
      const p={}
      Object.entries(this.categoryPoints).forEach(e => 
        p[e[0]]= Math.round(e[1]/this.maxPointByCategory[e[0]] * 100)
      )
      return p
    },
    labels() {
      return  Object.entries(this.percentageCategory).sort((a,b)=> b[1]-a[1]).map(e=> this.categoryLabels[e[0]])
    }
  }
};
</script>

<style scoped>

.group {
  width: 50%;
  border-radius: 5px;
  margin: 7% auto;
}

</style>