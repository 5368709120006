import Vue from 'vue'
import Router from 'vue-router'
import ResultsPage from './ResultsPage.vue'
import HomePage from './HomePage.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    //{
    //  path: '/',
    //  children: [
      {
        path: '/',
        redirect: 'home'
      },
      {
        path: '*',
        redirect: 'home'
      },
        {
          path: '/home',
          name: 'home',
          component: HomePage
        },
        {
          path: '/results/:id',
          name: 'results',
          component: ResultsPage,
        }
  //    ]
  //  }
  ]
})

export default router