<template>
  <div style="height:250px">
    <canvas ref="donutChart"></canvas>
    <div class="donut-chart-center"></div>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'DonutChart',
  //components: {
  //  Chart
  //},
  props:{
    labels:{
      type: Array,
      default: null
    },
    dataset:{
      type: Array,
      default: null
    },
    centerValue:{
      type: Number,
      default: null
    },
  },
  mounted() {
    this.createDonutChart()
  },
  methods: {
    createDonutChart() {
      const donutChartCanvas = this.$refs.donutChart //.getContext('2d');

      const data = {
        labels: [] ,//['Étiquette 1', 'Étiquette 2', 'Étiquette 3'],
        datasets: [
          {
            data: this.dataset,
            backgroundColor: ['#FFB100',  '#cdcdcd', '#F3F3F3'],
          },
        ],
      };
      const val = this.centerValue

      const options = {
        cutoutPercentage: 50,
        hover: false,
        tooltips: false,
        responsive: true,
        maintainAspectRatio: false,
        skipNull: true,
        aspectRatio: 0.5,
        animation: {
          onComplete: function () {
            var ctx = this.chart.ctx;
            ctx.font = "35px Arial";
            ctx.fillStyle = 'grey';
            ctx.textBaseline = "middle";

            var centerX = this.chart.width / 2 -30;
            var centerY = this.chart.height / 2 +10;
            ctx.fillText(val+'%', centerX, centerY);
          }
        }
      };

      new Chart(donutChartCanvas, {
        type: 'doughnut',
        data: data,
        options: options,
      });
    },
  },
};
</script>

<style>
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px; /* Spécifiez la largeur souhaitée */
  height: 250px; /* Spécifiez la hauteur souhaitée */
}
</style>